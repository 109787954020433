import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/layouts.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
      },
      {
        path: '/retail',
        name: 'retail',
        component: () => import(/* webpackChunkName: "about" */ '../views/retail.vue')
      },
      {
        path: '/wholesale',
        name: 'wholesale',
        component: () => import(/* webpackChunkName: "about" */ '../views/wholesale.vue')
      },
      {
        path: '/restaurant',
        name: 'restaurant',
        component: () => import(/* webpackChunkName: "about" */ '../views/restaurant.vue')
      },
      {
        path: '/ptuStore',
        name: 'ptuStore',
        component: () => import(/* webpackChunkName: "about" */ '../views/ptuStore.vue')
      },
      {
        path: '/ptuapp',
        name: 'ptuapp',
        component: () => import(/* webpackChunkName: "about" */ '../views/ptuapp.vue')
      },
      {
        path: '/service',
        name: 'service',
        component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue')
      },
      {
        path: '/appDownload',
        name: 'appDownload',
        component: () => import(/* webpackChunkName: "about" */ '../views/appDownload.vue')
      },
      {
        path: '/act',
        name: 'act',
        component: () => import(/* webpackChunkName: "about" */ '../views/appDownload.vue')
      },
      {
        path: "/agreement",
        name: "agreement",
        component: () => import(/* webpackChunkName: "about" */ '../views/agreement.vue')
      },
      {
        path: "/privacyPolicies",
        name: "privacyPolicies",
        component: () => import(/* webpackChunkName: "about" */ '../views/privacyPolicies.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
